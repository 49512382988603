import React from 'react';

import {
    postCardMeta,
    authorContainer,
    avatar as avatarClass,
    author as authorClass,
} from './post-card-meta.module.scss';
import { config } from '../../../config';
import { IAuthor } from '../../../models/author.model';
import { IPost } from '../../../models/post.model';
import { useTranslation } from '../../../hooks/use-translation';
import useMedia from '../../../hooks/use-media';

import Link from '../../atoms/link';
import Avatar from "../../atoms/avatar";
import Date from '../../atoms/date';

const { translationKeys, relations } = config;

export interface IPostCardMeta {
    author?: IAuthor;
    datePublished: IPost['publishedAt'];
}

export default function PostCardMeta({ author, datePublished }: IPostCardMeta) {
    const [avatar, alt] = useMedia({ media: author?.media, relation: relations.mainImage });
    const { firstName, lastName } = useTranslation(
        author,
        translationKeys.author
    );

    return (
        <div className={postCardMeta}>
            {author && (
                <Link to={author.slug} className={authorContainer}>
                    <Avatar
                        image={avatar}
                        className={avatarClass}
                        alt={alt}
                        size="small"
                    />
                    <p className={authorClass}>
                        {firstName} {lastName}
                    </p>
                </Link>
            )}
            <Date date={datePublished} />
        </div>
    );
}
