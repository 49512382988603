import { WebSite } from "schema-dts";

import { ISite } from "../../models/site.model";

export default function createWebsite(siteMetadata: ISite['siteMetadata']): WebSite {
    return {
        '@type': 'WebSite',
        '@id': `${siteMetadata.url}/#website`,
        url: siteMetadata.url,
        name: siteMetadata.title,
        description: siteMetadata.description,
        publisher: `${siteMetadata.url}/#organization`
    }
}