// extracted by mini-css-extract-plugin
export var imgBox = "post-card-module--img-box--qGI1b";
export var postCard = "post-card-module--post-card--hQXJ1";
export var postContent = "post-card-module--post-content--Cyuil";
export var postContentWrapper = "post-card-module--post-content-wrapper--Ae2fF";
export var postImageWrapper = "post-card-module--post-image-wrapper--MAk5h";
export var postSubtitle = "post-card-module--post-subtitle--tTQAz";
export var postTags = "post-card-module--post-tags--FE9d1";
export var postTitleContainer = "post-card-module--post-title-container--kuyav";
export var ratio = "post-card-module--ratio--x1pZZ";
export var tag = "post-card-module--tag--VTMBh";
export var topWrapper = "post-card-module--top-wrapper--caZMG";