import React from "react";
import { graphql } from "gatsby";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { authorBox } from "./author-page.module.scss";
import { config } from "../../config";
import QueryEdgeNode from "../../models/query-edge-node.model";
import { IPostBrief } from "../../models/post.model";
import { IAuthor } from "../../models/author.model";
import { IPageBase } from "../../models/page-base.model";
import { ISite } from "../../models/site.model";
import getNodes from "../../utils/get-nodes";
import { useTranslation } from "../../hooks/use-translation";
import usePageMetadata from "../../hooks/use-page-metadata";

import MainLayout from "../../layouts/main-layout";
import AuthorSection from "../organisms/sections/blog/author-section";
import BlogList from "../organisms/blog/blog-list";
import Section from "../molecules/section";

const { translationKeys } = config;

interface IAuthorPageProps extends IPageBase {
    readonly data: {
        author: IAuthor;
        allPost: QueryEdgeNode<IPostBrief>;
        site: ISite;
    };
}

const AuthorPage: React.FC<IAuthorPageProps> = ({ data, pageContext }) => {
    const { author, allPost } = data;
    const { paginationKeys } = pageContext;
    const { t } = useI18next();

    const posts = getNodes(allPost);
    const { firstName, lastName } = useTranslation(author, translationKeys.author);
    const { defaultStructuredData } = usePageMetadata(pageContext, data.site);

    return (
        <MainLayout
            hasPaddingTop={true}
            SEOProps={{
                title: `${firstName} ${lastName}`,
                structuredData: defaultStructuredData,
            }}
        >
            <AuthorSection
                className={authorBox}
                author={author}
                nameTag="h1"
                bottomText={t("author.bottomText")}
            />
            <Section>
                <BlogList posts={posts} authors={[author]} paginationKeys={paginationKeys} />
            </Section>
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!, $profileId: Int!, $postIds: [Int!]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        author(profileId: { eq: $profileId }) {
            ...authorFields
        }

        allPost(
            filter: { articleId: { in: $postIds } }
            sort: { fields: publishedAt, order: DESC }
        ) {
            edges {
                node {
                    ...postBriefFields
                }
            }
        }

        site {
            ...siteFields
        }
    }
`;

export default AuthorPage;
