import _ from 'lodash';
import { useRef, useEffect, useState, RefObject, useCallback } from 'react';

type UseIntersectionObserverOptions = Omit<IntersectionObserverInit, 'root'> &
    {
        root?: IntersectionObserverInit['root'] | string
    };
type UseIntersectionObserverResult<TargetRefType> =
    [RefObject<TargetRefType>, IntersectionObserverEntry | undefined, IntersectionObserver | null];


const DEFAULT_OPTIONS = { root: null };

export default function useIntersectionObserver<TargetRefType extends HTMLElement>(
    options: UseIntersectionObserverOptions,
    callback?: IntersectionObserverCallback
): UseIntersectionObserverResult<TargetRefType> {
    const [memoOptions, setMemoOptions] = useState({ ...DEFAULT_OPTIONS, ...options });
    const [entry, setEntry] = useState<IntersectionObserverEntry>();


    const targetRef = useRef<TargetRefType>(null);
    const observer = useRef<IntersectionObserver>(null);

    const observerCallback: IntersectionObserverCallback =
        useCallback(callback ? callback : entries => entries.forEach(item => setEntry(item)), []);

    useEffect(() => {
        if (!_.isEqual(memoOptions, { ...DEFAULT_OPTIONS, ...options })) {
            setMemoOptions({ ...DEFAULT_OPTIONS, ...options });
        }
    }, [options]);

    useEffect(() => {
        const { root, ...restOptions } = memoOptions;
        const rootElement = typeof root === 'string' ? document.querySelector(root) : root;

        //@ts-ignore (current is not readonly)
        observer.current = new IntersectionObserver(observerCallback, { root: rootElement, ...restOptions });

        if (targetRef?.current) {
            observer?.current.observe(targetRef.current);
        }

        return () => {
            observer?.current?.disconnect();

            if (observer) {
                //@ts-ignore
                observer.current = null;
            }
        };
    } ,[observerCallback, memoOptions]);

    return [targetRef, entry, observer?.current];
}