import { useMemo } from "react";

import { config } from '../../config';
import { IPostStructuredData } from "../../models/structured-data.model";
import { IPostBrief } from "../../models/post.model";
import { IAuthor } from "../../models/author.model";
import { useTranslation } from "../use-translation";
import usePostCardData from "./use-post-card-data";
import { getMediaWithRelation } from "../../utils/get-relation";
import { processStructuredDataObject } from '../../utils/process-structured-data';

const { relations, translationKeys } = config;


export default function usePostStructuredData(
    post: IPostBrief,
    author?: IAuthor
) {
    const { firstName, lastName, slug: authorSlug } = useTranslation(author, translationKeys.author);
    const { title, lead, slug, publishedAt } = usePostCardData(post);

    const postImage = getMediaWithRelation(post.media, relations.mainImage);
    const authorImage = author ? getMediaWithRelation(author.media, relations.mainImage) : undefined;

    //@ts-ignore
    const postStructuredData: IPostStructuredData = useMemo(() => processStructuredDataObject({
        name: title,
        description: lead,
        slug: slug,
        datePublished: publishedAt,
        dateModified: publishedAt,
        image: postImage?.url ? postImage?.url : '',
        author: {
            slug: authorSlug,
            firstName,
            lastName,
            image: authorImage?.url ? authorImage.url : ''
        }
    }), [title, lead, slug, publishedAt, postImage, authorSlug, firstName, lastName, authorImage]);

    return postStructuredData;
}