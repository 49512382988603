import { ImageObject } from "schema-dts";

export interface ICreateImageOptions {
    siteUrl: string,
    imageUrl: string,
    id?: string,
    caption?: string,
}

export default function createImage(options: ICreateImageOptions): ImageObject {
    const { id, caption, imageUrl, siteUrl } = options;
    return {
        '@type': 'ImageObject',
        url: imageUrl,
        contentUrl: imageUrl,
        '@id': id ? `${siteUrl}/${id}` : undefined,
        caption: caption ? caption : undefined
        //width
        //height
        //inLanguage
    }
}