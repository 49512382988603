import React from 'react';

import { ITag } from '../../models/tags.model';
import { useTranslation } from '../../hooks/use-translation';
import { config } from '../../config';
import useCheckIfInternal from '../../hooks/use-check-if-internal';

import Link from './link';

const { translationKeys } = config;

export interface TagProps {
    tag: ITag;
    className?: string;
    before?: string;
    after?: string;
}

export default function Tag({ tag, className, before, after }: TagProps) {
    const { slug, name } = useTranslation(tag, translationKeys.tag);
    const isSlugInternal = useCheckIfInternal(slug);

    return (
        <Link to={`/blog/${slug}`} className={className} external={!isSlugInternal}>
            {before && before}
            {name}
            {after && after}
        </Link>
    );
}
