import { useLocation } from "@reach/router";
import urlParser from "url";

const useCheckIfInternal = (url: string | undefined): boolean | undefined => {
    if (!url) return;
    const { hostname } = useLocation();
    const parsedUrl = urlParser.parse(url);
    return parsedUrl.hostname === null || parsedUrl.hostname === hostname;
}

export default useCheckIfInternal;