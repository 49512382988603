import React, { useEffect } from "react";
import * as CSS from "csstype";

import {
    postCard,
    topWrapper,
    postImageWrapper,
    ratio,
    postTitleContainer,
    postSubtitle,
    postContentWrapper,
    postContent,
    postTags,
    imgBox,
    tag as tagClass,
} from "./post-card.module.scss";
import { IPostBrief } from "../../../models/post.model";
import { IAuthor } from "../../../models/author.model";
import usePostCardData from "../../../hooks/blog/use-post-card-data";
import { IPostStructuredData } from "../../../models/structured-data.model";
import usePostStructuredData from "../../../hooks/blog/use-post-structured-data";

import PostCardMeta from "../../molecules/blog/post-card-meta";
import Title from "../../atoms/title";
import Tag from "../../atoms/tag";
import Link from "../../atoms/link";
import RatioImage from "../../atoms/ratio-image";

export interface IPostCardProps {
    post: IPostBrief;
    author?: IAuthor;
    style?: CSS.Properties;
    className?: string;
    onCreatePostItem?: (postItem: IPostStructuredData) => void;
    categoryId?: number;
}

const PostCard = React.forwardRef<HTMLDivElement, IPostCardProps>(
    ({ className = "", style, post, author, onCreatePostItem, categoryId }, ref) => {
        const {
            title,
            lead,
            slug,
            publishedAt,
            isSlugInternal,
            coverImage: { image: coverImage, alt },
            tags,
            categoryName,
        } = usePostCardData(post, categoryId);

        const postStructuredData = usePostStructuredData(post, author);

        useEffect(() => {
            if (onCreatePostItem) {
                onCreatePostItem(postStructuredData);
            }
        }, [postStructuredData, onCreatePostItem]);

        return (
            <div className={`${postCard} ${className}`} ref={ref} style={style}>
                <div className={topWrapper}>
                    <Link className={postImageWrapper} to={slug} external={!isSlugInternal}>
                        <RatioImage
                            image={coverImage}
                            alt={alt}
                            className={imgBox}
                            ratioClass={ratio}
                        />
                    </Link>
                    {/* TODO: w subtitle ma być kategoria */}
                    <Link to={slug} className={postTitleContainer} external={!isSlugInternal}>
                        <Title
                            subtitle={categoryName}
                            size="small"
                            subtitleClassName={postSubtitle}
                            Tag="h3"
                        >
                            {title}
                        </Title>
                    </Link>
                    <Link to={slug} className={postContentWrapper} external={!isSlugInternal}>
                        <p className={postContent}>{lead}</p>
                    </Link>
                </div>
                <div>
                    <p className={postTags}>
                        {tags.map((tag) => (
                            <Tag
                                key={tag.tagId}
                                tag={tag}
                                className={tagClass}
                                after={tags.length > 1 ? "," : ""}
                            />
                        ))}
                    </p>
                    <PostCardMeta author={author} datePublished={publishedAt} />
                </div>
            </div>
        );
    }
);

PostCard.displayName = "PostCard";

export default PostCard;
