import { useEffect, useState } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image/dist/src/components/gatsby-image.browser";
import { getImage } from "gatsby-plugin-image";

import { IMedia } from "../models/media.model";
import { getMediaWithRelation } from "../utils/get-relation";

export interface IUseMedia {
    media: IMedia[] | undefined;
    relation: number;
}

interface IMediaImage {
    image?: IGatsbyImageData;
    alt: string;
    url: string;
}

type UseMediaReturnValue = [IGatsbyImageData | undefined, string, string] & Partial<IMediaImage>;

export default function useMedia({ media, relation }: IUseMedia): UseMediaReturnValue {
    const [mediaImage, setMediaImage] = useState<IMediaImage>(getMediaImage(media, relation));

    useEffect(() => {
        setMediaImage(getMediaImage(media, relation));
    }, [media, relation]);

    const mediaArray: UseMediaReturnValue = [mediaImage.image, mediaImage.alt, mediaImage.url];
    mediaArray.image = mediaImage.image;
    mediaArray.alt = mediaImage.alt;
    mediaArray.url = mediaImage.url;

    return mediaArray;
}

function getMediaImage(media: IMedia[] | undefined = [], relation: number): IMediaImage {
    const mediaItem = getMediaWithRelation(media, relation);
    return {
        image: mediaItem?.remoteImage && getImage(mediaItem.remoteImage),
        alt: mediaItem?.alt || "",
        url: mediaItem?.url || "",
    };
}
