import React from "react";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import * as CSS from "csstype";

import { listing, pagination, emptyListInfo, visible } from "./blog-list.module.scss";
import { IPostBrief } from "../../../models/post.model";
import { IAuthor } from "../../../models/author.model";
import useMediaQuery from "../../../hooks/use-media-query";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import { IPostStructuredData } from "../../../models/structured-data.model";
import useBlogListStructuredData from "../../../hooks/blog/use-blog-list-structured-data";

import PostCard from "./post-card";
import Pagination from "../../molecules/pagination";

export interface IBlogListProps {
    posts: IPostBrief[];
    authors: IAuthor[];
    listClassName?: string;
    itemClassName?: string;
    paginationKeys?: string[];
    onCreatePostItems?: (postItems: IPostStructuredData[]) => void;
    categoryId?: number;
}

export default function BlogList(props: IBlogListProps) {
    const {
        posts,
        authors,
        listClassName,
        itemClassName,
        paginationKeys = [],
        onCreatePostItems,
        categoryId,
    } = props;

    const isBigPhone = useMediaQuery(570);
    const { pageKey, t } = useI18next();
    const { handleCreatePostItem } = useBlogListStructuredData(onCreatePostItems);

    if (posts.length === 0) {
        return <div className={emptyListInfo}>{t("blog-list.empty")}</div>;
    }

    return (
        <>
            <div className={`${listing} ${listClassName}`}>
                {posts.map((post, index) => {
                    const [
                        itemRef,
                        entry,
                        //eslint-disable-next-line
                    ] = useIntersectionObserver<HTMLDivElement>({
                        threshold: 0.1,
                    });

                    const count: CSS.Properties = {
                        ["--item-count" as any]: `${index}`,
                    };

                    return (
                        <PostCard
                            key={`list-item-${index}-${post.articleId}`}
                            post={post}
                            author={authors.find(
                                (author) => author.profileId === post.author.profileId
                            )}
                            ref={itemRef}
                            style={count}
                            className={`${itemClassName} ${entry?.isIntersecting ? visible : ""}`}
                            onCreatePostItem={handleCreatePostItem}
                            categoryId={categoryId}
                        />
                    );
                })}
            </div>
            <Pagination
                wrapperClassName={pagination}
                pageKey={pageKey}
                keys={paginationKeys}
                range={isBigPhone ? 1 : 2}
            />
        </>
    );
}
