import React from "react";
import { IGatsbyImageData } from "gatsby-plugin-image/dist/src/components/gatsby-image.browser";

import { container, ratioBox, small, medium, large } from "./avatar.module.scss";

import RatioImage from "./ratio-image";

export interface IAvatarProps {
    className?: string;
    image: IGatsbyImageData | undefined;
    alt?: string;
    size?: "small" | "medium" | "large";
}

const Avatar: React.FC<IAvatarProps> = ({ className = "", image, size, alt }) => {
    return (
        <RatioImage
            className={`${container} ${className} ${getSizeClass(size)}`}
            image={image}
            alt={alt}
            ratioClass={ratioBox}
        />
    );
};

function getSizeClass(size: IAvatarProps["size"] | undefined): string {
    if (size === "small") return small;
    if (size === "medium") return medium;
    if (size === "large") return large;
    return "";
}

export default Avatar;
