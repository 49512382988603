import { useCallback, useEffect, useState } from "react";

import { IPostStructuredData } from "../../models/structured-data.model";

export default function useBlogListStructuredData(callback?: (postItems: IPostStructuredData[]) => void) {
    const [postItems, setPostItems] = useState<Record<string, IPostStructuredData>>({});

    const handleCreatePostItem = useCallback((postData: IPostStructuredData) => {
        setPostItems(prevState => ({
            ...prevState,
            [postData.slug]: postData
        }))
    }, []);

    useEffect(() => {
        if (callback) {
            callback(Object.values(postItems));
        }
    }, [postItems])

    return { handleCreatePostItem };
}