import { Organization } from "schema-dts";

import { ISite } from "../../models/site.model";
import createImage, { ICreateImageOptions } from "./create-image";

export default function createOrganisation(siteMetadata: ISite['siteMetadata']): Organization {
    const imageData: ICreateImageOptions = {
        siteUrl: siteMetadata.url,
        imageUrl: `${siteMetadata.url}${siteMetadata.image}`,
        id: '#logo'
    }
    return {
        '@type': 'Organization',
        '@id': `${siteMetadata.url}/#organization`,
        url: siteMetadata.url,
        name: siteMetadata.title,
        //sameAs
        logo: createImage(imageData),
        image: `${siteMetadata.url}/#logo`
    }
}