import React from 'react';
import { date as dateClass } from './date.module.scss';
import moment from 'moment';

export interface IDateProps {
    date: number;
    className?: string;
}

export default function Date({ date, className }: IDateProps) {
    return (
        <p className={`${dateClass} ${className}`}>
            {moment.unix(date).format('DD-MM-YYYY')}
        </p>
    );
}
