import { config } from "../../config";
import { IPostBrief } from "../../models/post.model";
import { useTranslation } from "../use-translation";
import useMedia from "../use-media";
import useCheckIfInternal from "../use-check-if-internal";

const { translationKeys, relations } = config;

export default function usePostCardData(post: IPostBrief, categoryId?: number) {
    const { author, media, publishedAt, tags, categories } = post;
    const { title, lead, slug } = useTranslation(post, translationKeys.post);
    const [mainImageMedia, mainAlt] = useMedia({ media, relation: relations.mainImage });
    const [coverImageMedia, coverAlt] = useMedia({ media, relation: relations.coverImage });
    const isSlugInternal = useCheckIfInternal(slug);

    const category =
        categories.find((category) => category.categoryId === categoryId) || categories[0];

    return {
        author,
        title,
        lead,
        slug,
        mainImage: { image: mainImageMedia, alt: mainAlt },
        coverImage: { image: coverImageMedia, alt: coverAlt },
        publishedAt,
        isSlugInternal,
        tags,
        categoryName: category?.name || "",
    };
}
