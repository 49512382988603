import { useMemo } from "react";
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { ISitePageContext } from "../models/site-page.model";
import { ISite } from "../models/site.model";
import getSiteMetadata from "../utils/get-site-metadata";
import { createGraph, createOrganisation, createWebsite } from "../utils/structured-data";
import { GraphWithoutContext } from "../utils/structured-data/create-graph";

export default function usePageMetadata(pageContext: ISitePageContext, site?: ISite) {
    const { translations } = pageContext;
    const { language } = useI18next();
    const pageTitle = translations[language].label;
    let siteMetadata: ISite['siteMetadata'];
    let defaultStructuredData: GraphWithoutContext;

    if (site) {
        siteMetadata = useMemo(() => getSiteMetadata(site), [site]);
        if (siteMetadata) {
            defaultStructuredData = useMemo(() => {
                return createGraph(createOrganisation(siteMetadata), createWebsite(siteMetadata))
            }, [site]);
        }
    }

    return {
        pageTitle,
        //@ts-ignore
        siteMetadata,
        //@ts-ignore
        defaultStructuredData
    }
}